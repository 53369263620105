import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import StoryblokComponents from '../storyblok/StoryblokComponents';
import SEO from '../components/SEO/SEO';
import Container from '../components/Container';

//

const Page = ({ data }) => {
  const { page } = data;
  const content = JSON.parse(page.content);

  if (!content.block)
    return (
      <>
        <SEO />
        <Container>
          <h4 style={{ margin: '3.2rem' }}>
            Add some content to get started...
          </h4>
        </Container>
      </>
    );

  const blocks = content.block.map((block) =>
    React.createElement(StoryblokComponents(block.component), {
      key: block._uid,
      content: block,
    })
  );

  return (
    <>
      <SEO />
      {blocks}
    </>
  );
};

export default Page;

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

//

export const query = graphql`
  query($slug: String!) {
    page: storyblokEntry(slug: { eq: $slug }) {
      name
      content
    }
  }
`;
